<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Bank Account Management</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-3">
            <v-col class="d-flex" cols="12" md="2">
                <v-autocomplete
                    :items="bankCodes" 
                    label="Bank Code" 
                    v-model="filteredTransaction.bank" 
                    return-object
                    data-cy="bank-code-list"
                    @change="searchBankCode">
                </v-autocomplete>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Account Name" single-line @change="searchAccountName" data-cy="module-account-name"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Account Number" single-line @change="searchAccountNumber" data-cy="module-account-number"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-card-actions elevation="2" class="container--fluid grid-list-md text-center">
                    <v-btn dark medium color=success @click="queryData(true)" data-cy="button-search">
                        <v-icon dark left> mdi-magnify</v-icon>Search
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row class="text-left">
            <v-col class="d-flex" cols="12" md="2">
                <v-flex xs12 m6 d-flex>
                    <v-btn :block="$vuetify.breakpoint.mdAndDown" class="ma-5" color="success" @click.stop="showBankAccountForm('CREATED')" data-cy="button-create">Create Bank Account</v-btn>
                    <bank-account-form ref="bankAccountForm" :is-show="showFormDialog" :form-type="bankDetailsFormType" :listOfbankCodes="listOfBankCodes" :selected-item="selectedMerchant" v-on:save-bank-account-details="saveBankAccountDetails" v-on:close-dialog="closeDialog"/>
                </v-flex>
            </v-col>
        </v-row>
    </v-card>

    <template>
        <v-card>
            <v-data-table :hide-default-header="true" :headers="merchantHeaders" :items="displayMerchantTransactions" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMerchantTransactions" :footer-props="{'items-per-page-options': [10]}">
                <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr :colspan="headers.length">
                                <th v-for="header in merchantHeaders" :key="header.text">
                                    <span class="d-flex justify-center">{{header.text}}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr :colspan="merchantHeaders.length" v-for="item in items" :key="item.id">
                                <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.bank }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.accountName }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.accountNumber }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.createdBy }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.lastUpdated }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.lastUpdatedBy }} </span></td>
                                <td>
                                    <span class="d-flex justify-center">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="viewTransaction(item)" data-cy="list-action-bank-details">
                                                <v-icon dark medium>mdi-magnify-plus</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>View details</span> 
                                        </v-tooltip>   
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2"  @click.stop="showBankAccountForm('UPDATE', item)" data-cy="list-action-bank-update">
                                                <v-icon dark medium>mdi-pencil</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Update Bank Details</span> 
                                        </v-tooltip> 
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                            Found no results.
                        </v-alert>
                    </template>
            </v-data-table>
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import BankAccountForm from '@/views/components/bankAccountManagementForm.vue'

export default {
    name: 'Unmatched',
    components: {
        Loading,
        DetailsForm,
        BankAccountForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
    },
    data() {
        return {
            merchantHeaders: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Bank',
                    value: 'bank'
                },
                {
                    text: 'Account Name',
                    value: 'accountName'
                },
                {
                    text: 'Account Number',
                    value: 'accountNumber'
                },
                {
                    text: 'Create By',
                    value: 'createdBy'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Last Updated',
                    value: 'lastUpdated'
                },
                {
                    text: 'Last Updated By',
                    value: 'lastUpdatedBy'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            listOfBankCodes: [],
            bankCodes: this.listOfBankCodes ?? [],
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMerchantTransactions: 0,
            totalPayconnectTransctions: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayMerchantTransactions: [],
            displayPayconnectTransactions: [],
            remainderMerchantTransactions: [],
            remainderPayconnectTransactions: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            selected: [],
	    	selectAll: false,
            totalCount:'',
            tab: null,
            bankDetailsFormType: 'CREATE',
            selectedMerchant: {},

        }
    },
    watch: {
        options: {
            handler() {
                this.queryData(false);
                this.setData();
            },
        },
        listOfBankCodes: {
            handler(value) {
                this.bankCodes = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getBankCodes()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfBankCodes = listResponse.bankCodes
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var merchantTransactionList = undefined
                
                const parameters = {
                    bank: this.filteredTransaction.bank,
                    accountNumber: this.filteredTransaction.accountNumber,
                    accountName: this.filteredTransaction.accountName,
                    pageNo: pageNumber,
                    pageSize: itemsPerPage
                }

                merchantTransactionList = await API.getBankAccountList(parameters)
                if (!merchantTransactionList || merchantTransactionList.error) {
                    console.log(`${merchantTransactionList.error}`)
                } else {
                    var filteredList = merchantTransactionList.bankAccounts
                    var length = merchantTransactionList.rowCount
                    if (page > 1 && this.remainderMerchantTransactions.length > 0) {
                        filteredList = this.remainderMerchantTransactions.concat(filteredList)
                    }
                    // this.totalMerchantTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    this.totalMerchantTransactions = length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMerchantTransactions = filteredList.slice(0, end)
                    this.remainderMerchantTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMerchantTransactions / itemsPerPage

                    parameters.offset = this.totalMerchantTransactions
                    parameters.limit = 1
                    
                    const hasMoreResponse = merchantTransactionList
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.bankAccounts
                        if(hasMoreList.length > 0) {
                        // this.totalMerchantTransactions = Math.floor(this.totalMerchantTransactions/10) * 10 + 1
                        this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
               
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        searchBankCode: async function (val) {
            this.filteredTransaction.bank = val
            // this.queryData(true)
        },
        searchAccountName(val) {
            this.filteredTransaction.accountName = val
            // this.queryData(true)
        },
        searchAccountNumber(val) {
            this.filteredTransaction.accountNumber = val
            // this.queryData(true)
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
                this.$router.go(0);
            }
        },
        async saveBankAccountDetails(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new bank account.`
                    setTimeout(async () => {
                        const createResponse = await API.createBankAccount(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                            this.showFormDialog = false
                        } else {
                            this.loadingMessage = `Successfully created new bank account.`
                            this.showFormDialog = false
                            // this.$refs.bankAccountForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating bank account.`
                    setTimeout(async () => {
                        const updateResponse = await API.updateBankAccount(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                            this.showFormDialog = false
                        } else {
                            this.loadingMessage = `Successfully updated bank account.`
                            this.showFormDialog = false
                            // this.$refs.bankAccountForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.item = transaction
            this.isShowDetails = true
        },
        showBankAccountForm(what, item = {}) {
            console.log(what)
            this.bankDetailsFormType = what
            this.selectedMerchant = item
            this.showFormDialog = true
        },
    }
};
</script>

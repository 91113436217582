<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Bank Account Details</span>
                <span class="headline" v-else>Create Bank Account Details</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                            <v-autocomplete
                                v-model="form.bank"
                                :items="bankCodelist"
                                item-text="description"
                                item-value="description"
                                label="Bank Code*"
                                required :rules="[rules.requiredSelection]"
                                data-cy="form-bank-code"
                                clearable
                                return-object
                            ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    label="Account Name*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-account-name"
                                    v-model="form.accountName"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    label="Account Number*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-account-number"
                                    v-model="form.accountNumber"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        listOfbankCodes: Array,
    },
    computed: {
        populatedForm() {
            return {
                accountName: this.form.accountName,
                accountNumber: this.form.accountNumber,
                bank: this.form.bank,
                id: this.form.id,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
       listOfbankCodes: {
            handler(value) {
                this.bankCodelist = value ? value : [];
            },
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            bankCodelist: this.listOfbankCodes ?? [],
            form: {
                bankCode: {},
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                properEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid. Ex. juandelacruz@apollo.com.ph'
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-bank-account-details', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    accountName: undefined,
                    accountNumber: undefined,
                    bankCode: undefined,
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    id: this.selectedItem.id,
                    accountName: this.selectedItem.accountName,
                    accountNumber: this.selectedItem.accountNumber,
                    bank: this.selectedItem.bank ?? {},
                }
            }
        },
    }
}
</script>
